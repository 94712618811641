<!-- PayPalButton.vue -->
<template>
  <div class="container">
    <loader v-if="loading"></loader>
    <!-- <div id="paypal-button-container"></div> -->
    <div class="card-deck mb-3 text-center mb-container" v-if="responseAvailable === 1">
      <div class="card mb-4 box-shadow mb-container">
          <div class="card-header">
            <h4 class="my-0 font-weight-normal">Become Member</h4>
          </div>
          <div class="card-body mb-container">
            <h1 class="card-title pricing-card-title">$10 <small class="text-muted">/ mo</small></h1>
            <ul class="list-unstyled mt-3 mb-4">
              <li>You can watch live video</li>
              <li>Comment on Live chat thread</li>
              <li>Check other video library</li>
            </ul>
            <div id="paypal-button-container-P-37548089K3958141MMVGKJJI"></div>
          </div>
      </div>
      
    </div>
    <div v-else>
        Please Login for Purchase Payment
    </div>

  </div>
</template>

<script>
import {HTTP} from '../../_helper/http-constants.js';
import { EventBus } from '../../_helper/EventBus.js';

export default {
  data() {
    return {
      user: JSON.parse(window.localStorage.getItem('user')),
      currentDateTime: '',
      dateAfterOneMonth: '',
      loading: false,
      responseAvailable:null,
    };
  },
  created() {
		EventBus.$on('PaymentSuccess');
		
	},
  mounted() {
    //this.initPayPalScript();
    this.loading = true;
    const script = document.createElement("script");
    script.src = "https://www.paypal.com/sdk/js?client-id=AbTxf_Xmynoa2eNQ74ewAABy-ciEqHTwccupgyc3AKsJtL8NGFWhI2k19s_V7Np_3slkxSsyeN5oZsKN&vault=true&intent=subscription";
    script.async = true;
    this.responseAvailable = this.user.login
    script.onload = () => {
      // Once the script is loaded, initialize and render the PayPal button
      this.initializePayPalButtons();
      this.loading = false;
    }; 
    document.head.appendChild(script);
    
  },
  methods: {
   
    // Rest of the methods remain unchanged
    initializePayPalButtons() {
      // Add a comment to inform ESLint that 'paypal' is defined globally
      /* global paypal */

      paypal.Buttons({
        style: {
          shape: 'rect',
          color: 'gold',
          layout: 'vertical',
          label: 'subscribe'
        },
        createSubscription: function (data, actions) {
          return actions.subscription.create({
           // plan_id: 'P-37548089K3958141MMVGKJJI'
           plan_id: 'P-45B556108G915722JMZFNT5Y'
          });
        },
        onApprove: (data) => {
          this.loading = true;
          const formatDate = (date) => {
            const day = String(date.getDate()).padStart(2, '0');
            const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
            const year = date.getFullYear();
            const hours = String(date.getHours()).padStart(2, '0');
            const minutes = String(date.getMinutes()).padStart(2, '0');
            const seconds = String(date.getSeconds()).padStart(2, '0');
            
            return `${day}/${month}/${year}, ${hours}:${minutes}:${seconds}`;
          };

        const currentDate = new Date();
        this.currentDateTime = formatDate(currentDate);

        const dateAfterOneMonth = new Date(currentDate);
        dateAfterOneMonth.setMonth(dateAfterOneMonth.getMonth() + 1);
        this.dateAfterOneMonth = formatDate(dateAfterOneMonth);
         
          HTTP.post('https://djsnsapp.com/djsns/api/payment', {
            token: data.facilitatorAccessToken,
            user_id: this.user.user_id,
            subscriptionID: data.subscriptionID,
            startdate: this.currentDateTime,
            enddate: this.dateAfterOneMonth
          }).then(res => {
            
            console.log(res.data.message);
            EventBus.$emit('PaymentSuccess');
            //alert("PaymentSuccess");
            // this.$router.push('/livevideo');
            this.$router.push('/profile');

          }).catch(err => {
            this.loading = false;
            console.log(err);
          });

          //console.log('Login successful!', response.data.message);

        }
      }).render('#paypal-button-container-P-37548089K3958141MMVGKJJI');
    },
  },
};
</script>
